<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>TEST</h5>
        <iframe
          :src="Link"
          width="100%"
          height="1000px"
          frameborder="0"
        ></iframe>

       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Link: "https://docs.google.com/forms/d/e/1FAIpQLSeFQzw7j0yOBys-Kp2MRJtYSpLSCBbUoZgEjt_CuE9gIKqKdA/closedform?embedded=true",
    };
  },
};
</script>

<style></style>
